/* eslint-disable max-len */
import {
  get,
} from '@osrdata/app_core/dist/requests'
import { createApiThunk } from 'utils'
import { setSvg } from './slices'

const getTcoSvg = (state: 'on' | 'off') => createApiThunk(
  'interfaces/getTcoSvg',
  () => get(`/masters/static/interfaces/tco-${state}`),
  setSvg,
)

const getTptSvg = () => createApiThunk(
  'interfaces/getTptSvg',
  () => get('/masters/static/interfaces/tpt'),
  setSvg,
)
export {
  getTcoSvg,
  getTptSvg,
}

/* eslint-disable max-len */
import {
  get,
} from '@osrdata/app_core/dist/requests'
import { createApiThunk } from 'utils'
import {
  setSnciHtml,
} from './slices'

const getHtml = () => createApiThunk(
  'snci/getHtml',
  () => get('/masters/static/interfaces/snci'),
  setSnciHtml,
)

export {
  getHtml as getSnciHtml,
}
